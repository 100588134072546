<template>
    <v-progress-circular
        :model-value="((((timeleft)/1000)*100) / time)"
        :rotate="360"
        :size="60"
        :width="14"
        color="white"
    >
        <template #default>
            <p style="font-size: 22px; font-weight: 700;">
                {{ (timeleft)/1000 }}
            </p>
        </template>
    </v-progress-circular>
</template>

<script lang="ts" setup>
const props = defineProps({
    time: { type: Number, required: false, default: () => 10 },
    timeleft: { type: Number, required: false, default: () => 10 },
});

const time = computed(() => props.time);
const timeleft = computed(() => props.timeleft);
</script>
